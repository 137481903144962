.pLOm0W_card {
  min-width: 20rem;
  max-width: 20rem;
  transition: all .25s;
}

@media (width <= 991px) {
  .pLOm0W_card {
    min-width: 100%;
    max-width: 100%;
  }
}

.pLOm0W_card:hover {
  transform: scale(1.1);
}

.pLOm0W_card img {
  height: 15rem;
}

@media (width >= 992px) and (width <= 1200px) {
  .pLOm0W_card img {
    height: 12rem;
  }
}

@media (width <= 991px) {
  .pLOm0W_card img {
    height: auto;
  }
}
/*# sourceMappingURL=index.387d5c46.css.map */
